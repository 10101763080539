import axios from "axios";
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, node } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { compose } from 'redux';

import { Map } from '../../../components/Map/Map';
import { search } from '../../../config/configMaps';
import { obfuscatedCoordinates } from '../../../util/maps';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { USER_TYPE_PROVIDER } from '../../../util/types';
import * as validators from '../../../util/validators';

import {
  FieldLocationAutocompleteInput,
  FieldRadioButton,
  FieldSelect,
  FieldTextInput,
  Form,
  IconClose,
  IconCollection,
  PrimaryButton
} from '../../../components';

import css from './SignupForm.module.css';

const { LatLng } = sdkTypes;

const identity = v => v;

const getLatLong = async (props) => {
  const { values, index, name, form, onGetLatLong } = props;
  const { zipCodeRadius } = values;
  onGetLatLong({ inProgress: true, error: false, index });

  try {
    const country = search.countryLimit ? search.countryLimit.join(',') : "us";
    const access_token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const primaryZip = zipCodeRadius[index].primaryZip;
    if (primaryZip && primaryZip.length && primaryZip.trim()) {
      const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${primaryZip}.json?country=${country}&access_token=${access_token}`;
      const response = await axios.get(geocodingUrl);
      const { center, context } = response.data.features[0] || {};
      const cityContext = context && context.length && context.filter(context => context.id && context.id.startsWith('place'));
      const regionContext = context && context.length && context.filter(context => context.id && context.id.startsWith('region'));
      const countryContext = context && context.length && context.filter(context => context.id && context.id.startsWith('country'));
      const city = cityContext && cityContext.length ? cityContext[0].text : "";
      const postcode = primaryZip.trim();
      const region = regionContext && regionContext.length ? regionContext[0].text : "";
      const countryText = countryContext && countryContext.length ? countryContext[0].text : "";

      const centerLng = center[0];
      const centerLat = center[1];
      const previosValue = zipCodeRadius[index];
      const newValue = {
        ...previosValue,
        city,
        postcode,
        region,
        country: countryText,
        origin: { lat: centerLat, lng: centerLng },
      };
      onGetLatLong({ inProgress: false, error: false, index })
      form.change(name, newValue);
    } else {
      onGetLatLong({ inProgress: false, error: true, index })
      form.change(name, { ...zipCodeRadius[index], origin: false });
    }
  } catch (e) {
    onGetLatLong({ inProgress: false, error: true, index });
    form.change(name, { ...zipCodeRadius[index], origin: false });
    console.log(e, '&&& &&& => e');
  }
};

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        tab,
        intl,
        termsAndConditions,
        values,
        form,
        // config,
        // onGetLatLong,
        // latLongGetProgress,
        // latLongGetError,
        // latLongGetIndex,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || (tab == 'tradeshow' ? false : !(values && values.userType));

      // const additionalZipLabel = intl.formatMessage({ id: 'SignupForm.additionalZipLabel' });

      // const primaryZipLabel = intl.formatMessage({ id: 'SignupForm.primaryZipLabel' });
      // const primaryZipPlacehoder = intl.formatMessage({ id: 'SignupForm.primaryZipPlacehoder' });
      // const primaryZipRequired = intl.formatMessage({ id: 'SignupForm.primaryZipRequired' });

      // const serviceRadiusLabel = intl.formatMessage({ id: 'SignupForm.serviceRadiusLabel' });
      // const serviceRadiusRequired = intl.formatMessage({ id: 'SignupForm.serviceRadiusRequired' });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={classNames(css.name, tab == 'tradeshow' && css.smMargin)}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            {tab == 'tradeshow'
              ? <>
                {/* <FieldTextInput
                  type="text"
                  className={css.smMargin}
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.companyNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.companyNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.companyNameRequired',
                    })
                  )}
                /> */}

                <FieldTextInput
                  className={css.smMargin}
                  type="text"
                  id={formId ? `${formId}.dbaName` : 'dbaName'}
                  name="dbaName"
                  label={intl.formatMessage({
                    id: 'SignupForm.dbaNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.dbaNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.dbaNameValidate',
                    })
                  )}
                />

                <FieldLocationAutocompleteInput
                  rootClassName={css.locationInput}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="serviceLocation"
                  label={intl.formatMessage({ id: 'SignupForm.serviceLocationLabel' })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.serviceLocationPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.serviceLocation}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(intl.formatMessage({ id: 'SignupForm.serviceLocationRequired' })),
                    validators.autocompletePlaceSelected(intl.formatMessage({ id: 'SignupForm.serviceLocationNotRecognized' }))
                  )}
                  iconLocation={true}
                />
              </>
              : null}

            <div className={css.passwordWrapper}>
              <FieldTextInput
                className={classNames(css.password, tab == 'tradeshow' ? css.smMargin : null)}
                type={values.passwordType ? "text" : "password"}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
              <span
                type="button"
                className={css.passwordShow}
                onClick={() => form.change('passwordType', !values.passwordType)}
              >
                {values.passwordType
                  ? <IconCollection icon={"passwordShow"} />
                  : <IconCollection icon={"passwordHide"} />}
              </span>
            </div>

            {tab == 'tradeshow'
              ? null
              : <>
                <div className={css.userTypeLabel}>
                  <label>{intl.formatMessage({ id: 'SignupForm.userTypeLabel' })}</label>
                </div>
                <div className={css.selectRadioButtons}>
                  {/* <FieldRadioButton
                    className={css.radioBox}
                    id={formId ? `${formId}.customer` : 'customer'}
                    name="userType"
                    label={intl.formatMessage({ id: 'SignupForm.customer' })}
                    value={USER_TYPE_CUSTOMER}
                    checkedClassName={css.checkedAvailable}
                    validate={validators.required("required")}
                  /> */}
                  <FieldRadioButton
                    className={css.radioBox}
                    id={formId ? `${formId}.provider` : 'provider'}
                    name="userType"
                    label={intl.formatMessage({
                      id: 'SignupForm.provider',
                    })}
                    value={USER_TYPE_PROVIDER}
                    checkedClassName={css.checkedNotAvailable}
                    validate={validators.required("required")}
                  />
                </div>
              </>}
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;